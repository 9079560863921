<template>
	<b-container id="login" fluid class="text-center">
		<cg-loader :display="inProgress"></cg-loader>

		<b-form class="form-signin" @submit.prevent="login">
			<b-img fluid center class="mb-4" src="../assets/images/cg_logo_dark.svg" :alt="$t('Login.CyberGuruLogo')"></b-img>

			<span>{{ $t('Login.LoginMessage')}}</span>
			
			<div class="login-feedback mb-4">
				<cg-localized-alert alertVariant="danger" ref="cgLocAlert" :alertMessage="alertMessage" :alertParams="alertParams"></cg-localized-alert>
			</div>

			<label class="sr-only" for="email">{{ $t('General.Email') }}</label>
			<b-form-input class="top-input" v-model="email" type="email" id="email" required autofocus 
				:placeholder="$t('General.Email')"></b-form-input>
			<label class="sr-only" for="password">{{ $t('General.Password') }}</label>
			<b-form-input class="bottom-input" v-model="password" type="password" id="password" autocomplete="off" 
				required :placeholder="$t('General.Password')"></b-form-input>
			<b-button block variant="primary" type="submit" :disabled="inProgress">{{ $t("Login.LogIn") }}</b-button>
			<b-button block variant="transparent" type="button" @click="showHelpHint = !showHelpHint">
				{{ $t("Login.TroubleLogIn") }}
			</b-button>
			<span v-show="showHelpHint" v-html="helpMessage"></span>
		</b-form>

		<!-- Change password modal -->
		<b-modal id="change-password-modal" :title="$t('Profile.ChangePassword')" @show="cleanForm" hide-footer>
			<p>{{ $t('Login.UpdatePasswordMessage', {param: displayname ? displayname : email}) }}</p>
            <b-form @submit="onSubmit" @reset="onReset">
				<cg-localized-alert alertVariant="danger" ref="cgPwdAlert" :alertMessage="alertMessage" :alertParams="alertParams"></cg-localized-alert>

                <!-- Old password -->
                <b-form-group :state="oldPasswordState" :label="$t('Profile.OldPassword')" label-for="old-password-input">
                    <b-form-input id="old-password-input" v-model="form.oldPassword" :state="oldPasswordState" type="password" required></b-form-input>
                </b-form-group>

                <!-- New password -->
                <b-form-group :state="newPasswordState" :label="$t('Profile.NewPassword')" label-for="new-password-input" 
                    :invalid-feedback="$t('Profile.SamePasswords')">
                    <b-form-input id="new-password-input" autocomplete="new-password" v-model="form.newPassword" :state="newPasswordState" type="password" required></b-form-input>
                </b-form-group>

                <!-- Confirm password -->
                <b-form-group class="cg-form-group-description-space" :state="confirmPasswordState" :label="$t('Profile.ConfirmPassword')" 
                    label-for="confirm-password-input" :invalid-feedback="$t('Profile.PasswordsMismatch')">
                    <b-form-input id="confirm-password-input" autocomplete="new-password" v-model="form.confirmPassword" :state="confirmPasswordState" 
                        type="password" required></b-form-input>
                </b-form-group>
				
                <b-row align-h="end" class="cg-modal-footer pt-3 pb-1">
                    <b-col cols="auto">
                        <b-button type="reset">{{ $t('General.Cancel') }}</b-button>
                        <b-button 
							type="submit" 
							variant="primary" 
							:disabled="!oldPasswordState || !newPasswordState || !confirmPasswordState">
						{{ $t('Profile.ChangePassword') }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>
	</b-container>
</template>

<script>
import loginService from "@/services/login.service.js";
import syspropService from "@/services/sysprop.service.js"
import Vue from 'vue';

export default {
	name: "cg-login",
	data: function () {
		return {
			email: "",
			password: "",
			inProgress: false,
			showHelpHint: false,
			displayname: "",
			alertMessage: "",
			alertParams: {},
			helpEmail: null,
			showChangePassword: null,
            form: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            },
			passwordSettings: null
		}
	},
	computed: {
		helpMessage() {
			return this.helpEmail && this.$i18n.locale? this.$t('Login.ContactUs', { param: this.helpEmail }) : this.$t('Login.ContactSupport');
		},
		oldPasswordState() {
            return this.form.oldPassword.length == 0 ? null : true;
        },
        newPasswordState() {
            return this.form.newPassword.length == 0 ? null : this.form.newPassword.length > 1 && this.form.newPassword != this.form.oldPassword ? true : false;
        },
        confirmPasswordState() {
            return this.form.confirmPassword.length == 0 ? 
                null : this.form.confirmPassword.length > 1 && this.form.confirmPassword == this.form.newPassword ? true : false;
        }
	},
	methods: {
		login: function () {
			this.inProgress = true;
			let body = {
				email: this.email,
				password: this.password
			};
			this.$refs.cgLocAlert.hideAlert();
			this.alertMessage = "";
			loginService
			.login(body)
			.then((res) => {
				this.performLogin(res);
			}).catch(err => {
				if (err.response.status == 401) {
					this.alertMessage = "Login.LoginNotValid";
				} else if (err.response.status == 429) {
					const retrySecs = err.response.headers['retry-after'];
					this.alertMessage = "Login.TooMuchRequest";
					let timeLeft = this.$moment().seconds(retrySecs);
					let timeLeftFromNow = this.$moment().locale(this.$i18n.locale).to(timeLeft);
					this.alertParams = { param: timeLeftFromNow };				
				} else if (err.response.status == 999) {			
					this.$bvModal.show("change-password-modal");
					this.displayname = err.response.data.displayname;
				} else {
					this.alertMessage = "Login.ServerError";
				}
				this.$refs.cgLocAlert.showAlert();
				this.inProgress = false;
			});
		},
		onSubmit(evt) {
            evt.preventDefault();
            this.inProgress = true;

			let checkPassword = this.checkPasswordStrength(this.form.newPassword);
            if(checkPassword && checkPassword.length > 0) {
                this.alertMessage = 'Account.AccountPasswordError';
				this.alertParams = { param: checkPassword };
				this.alertVariant = 'danger';
                this.inProgress = false;
                this.$refs.cgPwdAlert.showAlert();
                return;
            }

            let body = {
                email: this.email,
                oldpassword: this.form.oldPassword,
                password: this.form.newPassword
            };

            loginService.changePassword(body).then((res) => {
                console.debug("Login - changePassword success", res);
				this.performLogin(res);
            }).catch((err) => {
                console.debug("Login - changePassword error", err);
                this.alertMessage = "Profile.PasswordChangeError";
                this.$refs.cgLocAlert.showAlert();
            }).finally(() => {
                this.inProgress = false;
                this.cleanForm();
            });
        },
		checkPasswordStrength(password) {
			const countUppercaseChars = (password.match(/[A-Z]/g) || []).length;
			const countLowercaseChars = (password.match(/[a-z]/g) || []).length;
			const countSpecialChars = (password.match(/[-!$%^&*()_+|~=`{};<>?,.@#]/g) || []).length;
			const countNumbers = (password.match(/[0-9]/g) || []).length;
			
			let errors = [];
			if(countUppercaseChars < this.passwordSettings.min_uppercase) {
				errors.push(this.$tc('Account.PasswordUppercaseError', (this.passwordSettings.min_uppercase)));
			}
			if (countSpecialChars < this.passwordSettings.min_special_chars) {
				errors.push(this.$tc('Account.PasswordSpecialCharsError', (this.passwordSettings.min_special_chars)));
			} 
			if (countLowercaseChars < this.passwordSettings.min_lowercase) {
				errors.push(this.$tc('Account.PasswordLowercaseError', (this.passwordSettings.min_lowercase)));
			}
			if(countNumbers < this.passwordSettings.min_numbers) {
				errors.push(this.$tc('Account.PasswordNumbersError', (this.passwordSettings.min_numbers)));
			}
			if (password.length < this.passwordSettings.min_length) {
				errors.push(this.$tc('Account.PasswordMinLengthError', (this.passwordSettings.min_length)));
			}

			return errors.join(', ');
		},
		performLogin(res) {
			this.loginSuccess = true;
			// on login success retrieve csrf token and store it in axios default headers
			loginService.getCsrfToken().then(function(csrf) {
				this.$http.defaults.headers.common['X-CSRF-TOKEN'] = csrf.data.csrfToken;
			});
			// store account informations
			var session = res.data
			// function used to view or hide elements based on account permission
			Vue.prototype.$checkPermission = function(permissions) {
				if(!permissions || !this.$account) {
					return false;
				}

				if(typeof permissions == 'string') {
					permissions = permissions.split(',');
				}
				return permissions.every((perm) => { 
					return this.$account.permissions.includes(perm);
				})

			}

			if(!session.timezone) {
				session.timezone = this.$moment.tz.guess();
			}
			
			Vue.prototype.$account = session;

			// Set app language based on account language
			this.$i18n.locale = session.language || 'en';
			this.$emit("loginSuccess", session);
			this.inProgress = false;
			this.$router.push("/");
		},
        onReset(evt) {
            evt.preventDefault();
            this.cleanForm();
        },
        cleanForm() {
            this.form.oldPassword = "";
            this.form.newPassword = "";
            this.form.confirmPassword = "";
            this.$nextTick(() => {
                this.$bvModal.hide("change-password-modal");
            });
        }
	},
	beforeMount() {
		this.$emit('sessionExpired');
	},
	created() {
		syspropService.getContact().then((res) => {
            this.helpEmail = res.data && res.data.contact;
		});

		syspropService.getPasswordSettings().then((res) => {
			this.passwordSettings = res.data && res.data.password_settings;
		})
	}
}
</script>
 
<style lang="less">
#change-password-modal {
	.cg-modal-footer {
		border-top: @cg-border-light;
		button:first-of-type {
			margin: 0 20px;
		}
	}
	.modal-dialog .white-overlay .alert-wrapper {
		width: 480px !important;
	}

	@media only screen and (max-width: 576px) { 
		.modal-dialog .white-overlay .alert-wrapper {
			width: 90% !important;
		}
	}
}
#login {
	margin-top: 5%;
	.form-signin {
		max-width: 400px;
		padding: 10px;
		margin: auto;
		.login-feedback {
			min-height: 90px;
			display: grid;
			align-items: center;
			.white-overlay {
				position: relative;
				.alert-wrapper {
					top: 10%;
					width: 100%;
				}
			}
		}
		.btn {
			position: relative;
			height: 45px;
			margin: 20px auto;
		}
		.form-control {
			position: relative;
			height: 45px;
			margin: -1px auto;
		}
		.form-control:focus {
			z-index: 2;
		}
		.top-input {
			border-radius: 0.3rem 0.3rem 0 0 !important;
		}
		.bottom-input {
			border-radius: 0 0 0.3rem 0.3rem !important;
			margin-bottom: -1px !important;
		}
	}
}	
</style>