import Vue from 'vue';
import moment from 'moment-timezone';

Vue.filter('dateHour', function (date, timezone) {
	return !date ? '' : moment.tz(date, timezone).format('MMMM Do YYYY, h:mm:ss');
})

Vue.filter('date', function (date) {
	return !date ? '' : moment.tz(date).format('MMMM Do YYYY');
})

Vue.filter('campaignDate', function (date, timezone) {
	return !date ? '' : moment.tz(date,timezone).format('DD/MM/YYYY');
})

Vue.filter('uppercase', function(text){
	return text.toUpperCase();
})

Vue.filter('lowercase', function(text){
	return text.toLowerCase();
})