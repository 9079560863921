import axios from '../plugins/axios.js'

class LoginService {

	login(data) {
		return axios.post("/auth/login", data);
	}

	changePassword(data) {
		return axios.post(`/auth/changepwd`, data);
	}

	logout() {
		return axios.post("/auth/logout");
	}

	validateSession() {
		return axios.get('/auth/validate');
	}

	getCsrfToken() {
		return axios.get('/auth/csrftoken');
	}
	
}

export default new LoginService();