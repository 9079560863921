import axios from '../plugins/axios.js'

class LandingPageService {

	getLandingPages(companyId, scope, filter) {
		return axios.get(`/landingpage`, { params: { companyId: companyId, scope: scope, searchText: filter } });
	}

	createLandingPage(companyId, body, name, language) {
		let data = { body: body, name: name, language: language, companyId: companyId };
		return axios.post(`/landingpage`, data);
	}

	copyLandingPage(landingPageId, body) {
		return axios.post(`/landingpage/${landingPageId}/copy`, body);
	}

	getLandingPageContent(landingPageId) {
		return axios.get(`/landingpage/${landingPageId}`);
	}

	deleteLandingPage(landingPageId) {
		return axios.delete(`/landingpage/${landingPageId}`);
	}

	deleteLandingPageContent(landingPageId, language) {
		return axios.delete(`/landingpage/${landingPageId}/${language}`);
	}

	updateLandingPageInfo(landingPageId, body) {
		return axios.put(`/landingpage/${landingPageId}`, body);
	}

	updateLandingPage(landingPageId, language, body) {
		return axios.put(`/landingpage/${landingPageId}/content/${language}`, body);
	}

	addLandingPageLanguage(landingPageId, language, body) {
		return axios.put(`/landingpage/${landingPageId}/${language}`, body);
	}

	importLandingPage(landingPageId, data) {
		return axios.post(`/landingpage/${landingPageId}/import`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	getPreviewHints() {
		return axios.get(`/landingpage/hints`);
	}

}

export default new LandingPageService();