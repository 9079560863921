<template>
    <transition name="slide-fade">
        <b-card v-if="$checkPermission('view-landing-page')" class="mb-3">

            <template v-slot:header>
                <b-row>
                    <b-col>
                        <b-card-text class="ellipsable-text">{{ landingPageId }} - {{ landingPageName }}</b-card-text>
                    </b-col>
                </b-row>
            </template>
            
            <div class="preview-sm-container">
                <b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="loading"></b-spinner>
                <div class="preview-sm">
                    <iframe v-if="previewSm" :srcdoc="previewSm" frameborder="0" scrolling="no"></iframe>
                </div>
            </div>
            <div v-if="isGlobal" class="global-scope-icon"><font-awesome-icon :icon="['fas', 'globe']" /></div>
            <div class="iframe-overlay" @click="showPreview()"></div>

            <!-- Landing page version deletion confirmation modal -->
            <b-modal :id="'landing-page-'+landingPageId+'delete-confirm'"  content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
                bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('LandingPage.ConfirmDeleteTitle')" @ok="deleteLandingPageContent">
                
                <p class="my-2 text-left" v-html="$t('LandingPage.ConfirmDeleteContent', { param: $langs[previewLanguage] })"></p>
            </b-modal>

            <!-- Modal for unsaved info -->
            <b-modal :id="'unsavedLPInfo-'+landingPageId" content-class="shadow" size='md' buttonSize='md' okVariant='danger'
                bodyClass="text-center" footerClass="p-2" :centered='true' @ok="undoUnsavedChanges" :title="$t('General.UnsavedInfo')">
                <p class="my-2 text-left">{{ $t('General.UnsavedInfoText') }}</p>
            </b-modal>

            <!-- Landing page Modal preview -->
            <b-modal id="landing-page-modal" size="xl" ref="lpModal" :title="landingPageId + ' - ' +landingPageName" body-class="modal-body-full-width" @hide="manageUnsavedInfo">
                <cg-localized-alert :alertVariant="alertVariant" ref="cgLocModalAlert" :alertMessage="alertMessage" :alertParams="alertParams"></cg-localized-alert>
                <b-tabs card>
                    <!-- Landing page preview tab -->
                    <b-tab :title="$t('LandingPage.Preview')" active>
                        <div>
                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="languageLPSelect" :label="$t('Campaigns.AvailableLanguages')"
                                label-align-sm="right">
                                <b-form-select id="languageLPSelect" size="sm" v-model="previewLanguage" @input="changePreviewLanguage" 
                                    :options="langOptions">
                                </b-form-select>
                            </b-form-group>
                        </div>

                        <!-- Show Preview/Editor toggle button -->
                        <b-row align-h="end">
                            <b-col cols="auto">
                                <b-button v-if="$checkPermission('modify-landing-page')" :disabled="!isAdmin && isGlobal" @click="confirmDeleteLandingPageContent" size="sm" variant="primary" class="mb-3">
                                    <font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'trash-alt']"/>
                                    {{ $t('LandingPage.DeleteLandingPageContent') }}
                                </b-button>
                            </b-col>
                            <b-col cols="auto">
                                <b-button v-if="$checkPermission('modify-landing-page')" @click="showEditor = !showEditor" size="sm" variant="primary" class="mb-3" :disabled="!isAdmin && isGlobal">
                                    <font-awesome-icon v-if="showEditor" class="vertical-align-middle mr-2" :icon="['fas', 'eye']"/>
                                    <font-awesome-icon v-else class="vertical-align-middle mr-2" :icon="['fas', 'edit']"/>
                                    {{ showEditor? $t('LandingPage.ShowPreview') : $t('LandingPage.ShowEditor') }}
                                </b-button>
								<b-button size="sm" variant="primary" class="ml-2 mb-3" @click="saveLPBody" :disabled="disableSaveBodyButton">{{ $t('General.Save') }}</b-button>
                            </b-col>
                        </b-row>

                        <!-- Landing page Preview/Editor -->
                        <div class="preview-lp-lg">
                           <prism-editor v-if="showEditor" :code="codePreview" :lineNumbers="true" @change="updateEditorPreview"></prism-editor>
                           <iframe v-if="previewLg && !showEditor" :srcdoc="previewLg" frameborder="0" width="100%"></iframe>
                        </div>
                    </b-tab>

                    <!-- Landing page details -->
                    <b-tab :title="$t('LandingPage.Details')" v-if="$checkPermission('create-landing-page,modify-landing-page')">

                        <b-form-group label-cols-lg="12" :label="$t('LandingPage.BasicInfo')" label-size="lg" label-class="label-profile pt-0" class="mb-0">
                            <!-- Landing page name -->
                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="landingPageName" :label="$t('LandingPage.Name')" label-align-sm="right">
                                <b-form-input id="landingPageName" v-model="currentLP.name" :disabled="!isAdmin && isGlobal"></b-form-input>
                            </b-form-group>
                        </b-form-group>

                        <b-row align-h="end">
							<b-col cols="auto" class="pr-3 mb-3">
								<b-button variant="primary" class="btn-action my-1 mx-0" @click="updateLandingPageInfo" :disabled="disableSaveNameButton">{{ $t('General.Save') }}</b-button>
							</b-col>
						</b-row>

                        <div class="separator mb-4"></div>

                        <b-form-group label-cols-lg="12" :label="$t('LandingPage.AddContent')" label-size="lg" label-class="label-profile pt-0">
                            <!-- Import HTML -->
                            <div class="mt-2 mx-3">
                                <b-card-text class="mb-4" v-html="$t('LandingPage.ImportFromFile')"></b-card-text>
                                <!-- File browser for Landing page import -->
                                <b-form-group class="form-align-self-center" label-sr-only label-cols-sm="2" label-for="importLandingPageInput">
                                    <b-form-file v-model="landingPageHTML"
                                        :placeholder="$t('LandingPage.ImportHTML')"
                                        accept=".html"
                                        id="importLandingPageInput"
                                        ref="importLandingPageInput"
                                        @input="importLandingPage"
                                        :disabled="!isAdmin && isGlobal">
                                    </b-form-file>
                                </b-form-group>
                            </div>

                            <!-- New Content for language -->
                            <div class="mt-4 pt-4 mx-3">
                                <b-card-text class="mb-4" v-html="$t('LandingPage.NewLandingPageLanguage')"></b-card-text>
                                <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="languageNewLPSelect" :label="$t('Campaigns.AvailableLanguages')"
                                    label-align-sm="right">
                                    <b-form-select id="languageNewLPSelect" v-model="newLandingPageLanguage" :options="$langs" :disabled="!isAdmin && isGlobal"></b-form-select>
                                </b-form-group>

                                <b-row align-h="end">
                                    <b-col cols="auto" class="pr-3">
                                        <b-button variant="primary" class="btn-action my-1 mx-0" :disabled="!newLandingPageLanguage" @click="addLandingPageLanguage()">
                                            {{ $t('LandingPage.AddLandingPageContent') }}</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form-group>

                    </b-tab>

                    <!-- Company association -->
                    <b-tab :title="$t('LandingPage.Company')" v-if="$checkPermission('list-company')">
                        <div class="mt-4 mx-3">
                            <b-card-text class="mb-4">{{ $t('LandingPage.AssignLPToCompany') }}</b-card-text>
                            <b-card no-body class="company-container my-4 px-3">
                                <b-row v-for="(company, index) in companyList" :key="index" :id="'lp-' + index" class="separator company py-2">
                                    <b-col class="line-height-30 text-truncate">{{ company.name }}</b-col>
                                    <b-col cols="auto" align-self="center" class="line-height-30">
                                        <b-form-checkbox :name="company.name" :id="'assign-'+company.name+'-'+landingPageId+'-'+index" v-model="company.assigned" switch size="lg" 
                                            @input="assignToCompany(company.company_id, company.assigned)" :disabled="!isAdmin && isGlobal">
                                            <span class="sr-only">{{company.name}}</span>
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-card>
                            <b-row align-h="end">
                                <b-col cols="auto" class="pr-3 mb-3">
                                    <b-button variant="primary" class="btn-action my-1 mx-0" @click="saveAssignToCompanyInfo" :disabled="disableAssignToCompanyButton" >{{ $t('General.Save') }}</b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>

                    <b-tab :title="$t('LandingPage.Advanced')" v-if="$checkPermission('modify-landing-page')">

                        <!-- Capture credentials -->
                        <b-form-group label-cols-lg="12" :label="$t('LandingPage.Settings')" label-size="lg" label-class="label-profile pt-0" class="mb-4">
                            <span>{{ $t('LandingPage.CaptureCredentialInfo') }}</span>
                            <b-card no-body class="my-4 px-3">
                                <b-row class="separator company py-2">
                                    <b-col class="line-height-30 text-truncate">{{ $t('LandingPage.CaptureCredentials') }}</b-col>
                                    <b-col cols="auto" align-self="center" class="line-height-30">
                                        <b-form-checkbox id="lpCaptureCredentials" v-model="landingPage.capture_credentials" :value="1" :unchecked-value="0" switch size="lg" :disabled="!isAdmin && isGlobal">
                                            <span class="sr-only">{{ $t('LandingPage.CaptureCredentials') }}</span>
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-card>
                            <div v-if="landingPage.capture_credentials">
                                <!-- Capture password -->
                                <span>{{ $t('LandingPage.CapturePasswordInfo') }}</span>
                                <b-card no-body class="my-4 px-3">
                                    <b-row class="separator company py-2">
                                        <b-col class="line-height-30 text-truncate">{{ $t('LandingPage.CapturePassword') }}</b-col>
                                        <b-col cols="auto" align-self="center" class="line-height-30">
                                            <b-form-checkbox id="lpCapturePassword" v-model="landingPage.capture_passwords" :value="1" :unchecked-value="0" switch size="lg" :disabled="!isAdmin && isGlobal">
                                                <span class="sr-only">{{ $t('LandingPage.CapturePassword') }}</span>
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <!-- Redirect users to URL -->
                                <span v-html="$t('LandingPage.RedirectToInfo')"></span>
                                <b-form-group class="form-align-self-center mt-4" label-cols-sm="3" label-for="lpRedirectTo" :label="$t('LandingPage.RedirectTo')" label-align-sm="right">
                                    <b-form-input id="lpRedirectTo" v-model="landingPage.redirect_url" :disabled="!isAdmin && isGlobal"></b-form-input>
                                </b-form-group>
                            </div>
                        </b-form-group>

                        <b-row align-h="end">
							<b-col cols="auto" class="pr-3">
								<b-button variant="primary" class="btn-action my-1 mx-0" @click="updateLandingPageInfo" :disabled="disableSaveSettingsButton">{{ $t('General.Save') }}</b-button>
							</b-col>
						</b-row>

                        <div v-if="$checkPermission('modify-template') && companyOptions.length >= 1">
                            <div class="separator mt-2 mb-4"></div>

                            <b-form-group label-cols-lg="12" :label="$t('LandingPage.CopyLandingPage')" label-size="lg" label-class="label-profile pt-0" >
                                <b-row id="copyLandingPageCompanySelect" class="mt-2">
                                    <b-col class="col-sm-9">
                                        <b-form-select id="copyLandingPageCompanySelect" v-model="landingPageCopyTargetCompany" :options="companyOptions" :disabled="!isAdmin && isGlobal"></b-form-select>
                                    </b-col>
                                    <b-col class="col-sm-3 pl-0">
                                        <b-button variant="primary" id="copyLandingPageButton" class="btn-action w-100" @click="copyLandingPage" :disabled="!isAdmin && isGlobal">{{ $t('General.Copy') }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </div>
                    </b-tab>
                </b-tabs>

                <template v-slot:modal-footer>
                    <b-row class="landing-page-modal-footer" align-v="center">
                        <b-col>
                            <span v-html="supportMessage"></span>
                        </b-col>
                        <!-- Delete Landing page button -->
                        <b-col cols="auto" class="pr-2" v-if="landingPageId > 1">
                            <b-button v-if="$checkPermission('delete-landing-page')" variant="primary" class="btn-action my-1 mx-0" @click="confirmDeleteLandingPage()" :disabled="!isAdmin && isGlobal">
                                {{ $t('LandingPage.DeleteLandingPage') }}</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

        </b-card>
    </transition>
</template>

<script>
import landingpageService from '@/services/landingpage.service';
import companyService from '@/services/company.service';

export default {
    name: "cg-lp-card",
    data: function() {
        return {
            loading: true,
            showEditor: false,
            alertVariant: 'danger',
            alertMessage: '',
            alertParams: {},
            previewLanguage: '',
            langOptions: [],
            companyList: [],
			lpContent: null,
			currentLPContent: null,
            previewLg: null,
            previewSm: null,
            codePreview: null,
            currentLP: {
                capture_credentials: null,
                capture_passwords: null,
                company_id: null,
                name: null,
                page_id: null,
                redirect_url: null,
                scope: null
            },
            landingPageHTML: null,
            landingPageName: null,
            newLandingPageLanguage: null,
            companyId: localStorage.getItem('cg-company'),
            companyOptions: [],
            landingPageCopyTargetCompany: null,
            companyToSave: []
        };
    },
    props: ["landing-page", "index", "company-data", "preview-hints"],
    computed: {
        landingPageId() {
            return this.landingPage.page_id;
		},
		disableSaveBodyButton() {
			if(!this.lpContent || !this.currentLPContent) {
				return true;
            }
            let savedData = this.lpContent.find(lp => { return lp.language == this.previewLanguage });
            let previousData = this.currentLPContent.find(lp => { return lp.language == this.previewLanguage });
	
			return JSON.stringify(previousData.body) === JSON.stringify(savedData.body);
		},
        disableSaveNameButton() {
            return this.currentLP && this.landingPage && this.currentLP.name == this.landingPage.name;
        },
        disableSaveSettingsButton() {
            return this.currentLP && this.landingPage &&
                this.currentLP.redirect_url == this.landingPage.redirect_url && 
                this.currentLP.capture_credentials == this.landingPage.capture_credentials && 
                this.currentLP.capture_passwords == this.landingPage.capture_passwords;
        },
        disableAssignToCompanyButton() {
            return this.companyToSave && this.companyToSave.length == 0;
        },
        isAdmin() {
            return this.$checkPermission('list-company-all');
        },
        isGlobal() {
            return !this.landingPage.company_id ? true : false; 
        }
    },
    methods: {
        async importLandingPage() {
			if(this.landingPageHTML) {
				this.loading = true;
				let landingPageFormData = new FormData();
				landingPageFormData.append('landingPageFile', this.landingPageHTML);
				try {
					let resultImport = await landingpageService.importLandingPage(this.landingPageId, landingPageFormData);
					console.log("LandingPageCard - importLandingPage - resultImport", resultImport);
					this.alertVariant = 'success';
					this.alertMessage = 'LandingPage.ImportSuccess';
                    
                    this.getLandingPageContent();
                    this.$emit('landing_page_reload', this.landingPageId);
				} catch (error) {
					this.alertVariant = 'danger';
                    this.alertMessage = 'LandingPage.ImportError';
                    this.alertParams = { error : error.response.data.error };
				} finally {
                    this.loading = false;
                    this.$refs.cgLocModalAlert.showAlert();
					this.landingPageHTML = null;
				}
			}
        },
        updateLandingPageInfo() {
            this.loading = true;

            if(!this.landingPage.capture_credentials) {
                this.landingPage.capture_passwords = 0;
                this.landingPage.redirect_url = '';
            }

            landingpageService.updateLandingPageInfo(this.landingPageId, this.landingPage).then(() => {
                console.debug("LandingPage - UpdateLandingPageInfo success", this.landingPageId);
                this.alertVariant = 'success';
                this.alertMessage = 'LandingPage.UpdateLandingPageInfoSuccess';
                this.landingPageName = this.landingPage.name;
                
                this.currentLP = Object.assign({}, this.landingPage);
                this.$emit('landing_page_reload', this.landingPageId);
            }).catch((error) => {
                console.error("LandingPage - updateLandingPageInfo error", this.landingPageId, error);
                this.alertVariant = 'danger';
                this.alertMessage = 'LandingPage.UpdateLandingPageInfoError';
            }).finally(() => {
                this.loading = false;
                this.$refs.cgLocModalAlert.showAlert();
            });
        },
        addLandingPageLanguage() {
            // If the choosen language has been previously added, return
            let lang = this.langOptions.filter((lang) => { return lang.value === this.newLandingPageLanguage });
            if(lang.length != 0) {
                console.debug("LandingPageCard - language already exists", this.newLandingPageLanguage);
                this.alertVariant = 'danger';
                this.alertMessage = 'LandingPage.AddExistingContentError';
                this.$refs.cgLocModalAlert.showAlert();
                return;
            }
            this.loading = true;
            landingpageService.addLandingPageLanguage(this.landingPageId, this.newLandingPageLanguage, { body: '<html></html>' }).then(() => {
                console.debug("LandingPage - addLPLanguage success", this.landingPageId);
                this.alertVariant = 'success';
                this.alertMessage = 'LandingPage.AddContentSuccess';
                this.alertParams = { param: this.$langs[this.newLandingPageLanguage] };
                this.getLandingPageContent();
            }).catch((error) => {
                console.error("LandingPage - addLPLanguage error", this.landingPageId, error);
                this.alertVariant = 'danger';
                this.alertMessage = 'LandingPage.AddContentError';
            }).finally(() => {
                this.loading = false;
                this.$refs.cgLocModalAlert.showAlert();
                this.newLandingPageLanguage = null;
            });
        },
        confirmDeleteLandingPageContent() {
            console.debug("LandingPage - deleteLandingPageContent - open confirmation modal for", this.landingPageId, this.previewLanguage);

            this.$bvModal.show('landing-page-' + this.landingPageId + 'delete-confirm');
        },
        async deleteLandingPageContent() {
            this.loading = true;
            landingpageService.deleteLandingPageContent(this.landingPageId, this.previewLanguage).then(() => {
                console.debug("LandingPage - confirmDeleteLandingPageContent success", this.landingPageId);
                this.alertVariant = 'success';
                this.alertMessage = 'LandingPage.DeleteContentSuccess';
                this.alertParams = { param: this.$langs[this.previewLanguage] };
                this.getLandingPageContent(false);
            }).catch(err => {
                console.error("LandingPage - confirmDeleteLandingPageContent error", err);
				this.alertMessage = 'LandingPage.DeleteError';
                this.alertVariant = 'danger';
			}).finally(() => {
                this.loading = false;
                this.$refs.cgLocModalAlert.showAlert();
            });
        },
        confirmDeleteTitle() {
			return this.$i18n.t("LandingPage.ConfirmDeleteTitle");
		},
		confirmDeleteMessage() {
			return this.$i18n.t("LandingPage.ConfirmDeleteMessage");
		},
		confirmDeleteLandingPage() {
			// This function displays a modal for the user to confirm the deletion
			this.$bvModal.msgBoxConfirm(this.confirmDeleteMessage(), {
				title: this.confirmDeleteTitle(),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				// If the user confirms the deletion, value is not null
				if (value) {
					this.deleteLandingPage();
				}
			}).catch(err => {
				// The user canceled the action
				console.error("LandingPage - confirmDeleteLandingPage error", err);
			});
		},
        async deleteLandingPage() {
            landingpageService.deleteLandingPage(this.landingPageId).then(() => {
                console.debug("LandingPage - deleteLandingPage success", this.landingPageId);
                this.$refs.lpModal.hide();
                this.$emit('landing_page_deleted', this.landingPageId);
            }).catch(err => {
                console.error("LandingPage - deleteLandingPage error", err);
				this.alertMessage = 'LandingPage.DeleteError';
                this.alertVariant = 'danger';
                this.$refs.cgLocModalAlert.showAlert();
			});
        },
        async getLandingPageContent(languageSet) {
            let result = await landingpageService.getLandingPageContent(this.landingPageId);
            this.lpContent = result.data;

            this.langOptions = [];
            this.lpContent.forEach((item) => {
                let text = this.$t("Languages." + item.language);
                if(text.indexOf(".") != -1) {
                    // If the language isn't in the Catalog, provide another translation
                    text = this.$langs[item.language];
                }
                this.langOptions.push({ value: item.language, text: text });
            });

            this.changePreviewLanguage(languageSet);
            
            this.currentLPContent = JSON.parse(JSON.stringify(this.lpContent));

            this.loading = false;
        },
        compilePreview(lpContent, language, isPreview) {
            return new Promise((resolve, reject) => {
                try {
                    let lp = lpContent.filter((t) => { return t.language == language; });
                    if(lp.length == 0) {
                        lp = lpContent.filter((t) => { return t.language == this.$account.language; });
                        if(lp.length == 0 && this.companyData && this.companyData.company) {
                            lp = lpContent.filter((t) => { return t.language == this.companyData.company.default_language; });
                        }
                        if(lp.length == 0) {
                            lp = lpContent.filter((t) => { return t.language == 'en'; });
                            if(lp.length == 0) {
                                lp = lpContent;
                            }
                        }
                    }
                    
                    lp = lp[0];

                    if(this.previewHints && isPreview) {
                        // Replace hint placeholder
                        let hintString = `<ul>`;
                        let hintCount = 0;

                        this.previewHints.forEach((hint) => {
                            if(hintCount == 4) {
                                return;
                            }
                            if(hint.language == this.previewLanguage) {
                                hintString += `<li><span class="hint">${hint.body}</span></li>`;
                                hintCount++;
                            }
                        });

                        hintString += `</ul>`;
                        lp.body = lp.body.replace(/{{.Top4HintsAsHtml}}/gim, hintString);  

						let baseURL = this.companyData.gp_landing_url;
						if(baseURL) {
							lp.body = lp.body.replace(/{{.BaseURL}}/gm, baseURL);
						}
                    }
     
                    resolve(lp.body);
                } catch (error) {
                    reject(error);
                }
            })
        },
        assignToCompany(companyId, assignAction) {
            this.companyToSave.push({company_id: companyId, assigned: assignAction });
        },
        saveAssignToCompanyInfo() {
            // AssignAction can be true or false with the following meaning:
			// true => assign this Landing page to the given Company
            // false => remove the association between this Landing page and the given Company
            this.companyToSave.forEach(async(element) => {
                await companyService.assignLandingPage(element.company_id, this.landingPageId, element.assigned);
            });
            this.companyToSave = [];
        },
        async showPreview() {
            let result = await companyService.getCompanyList();
            this.companyList = result.data.sort((a, b) => { return ('' + a.name).localeCompare('' + b.name); });

            // Exclude current company from list
            let filteredCompanyList = this.companyList.filter((c) => { return c.company_id !== this.landingPage.company_id; });

            if (!this.isGlobal) {
                let company = this.companyList.find(c => { return c.company_id == this.companyId });
                this.companyList = [];
                this.companyList.push(company);
            }
            
            this.companyList.map((comp) => {
                comp.assigned = (comp.landing_page_id == this.landingPageId);
            });

            this.landingPageCopyTargetCompany = filteredCompanyList.length ? filteredCompanyList[0].company_id : null;

            // Check if the logged user is admin
            if (this.$checkPermission('list-company-all') && (this.landingPage.company_id)) {
                this.landingPageCopyTargetCompany = null;
                this.companyOptions.push( { value: null, text: this.$t('LandingPage.Global') } );	
            }

            filteredCompanyList.forEach(c => {
                this.companyOptions.push( { value: c.company_id, text: c.name } );
            });

            this.$refs.lpModal.show();
            this.changePreviewLanguage();
        },
        assignPreviewLanguage(languageSet) {
            // Find the Landing page version written for locale language setting
            if (!languageSet) {
                this.previewLanguage = this.lpContent.find(element => element.language == this.$i18n.locale) ? this.$i18n.locale : null;
                if (this.previewLanguage == null) {
                    // If the 'locale' version does not exist, try displaying the Landing page in English, otherwise pick the first available language
                    this.previewLanguage = this.lpContent.find(element => element.language == "en") ? "en" : this.lpContent[0].language;
                }
            }
        },
        async changePreviewLanguage(languageSet) {
            this.assignPreviewLanguage(languageSet);

            this.previewLg = await this.compilePreview(this.lpContent, this.previewLanguage, true);
            this.previewSm = await this.compilePreview(this.currentLPContent, this.previewLanguage, true);
            this.codePreview = await this.compilePreview(this.lpContent, this.previewLanguage, false);
        },
        async updateEditorPreview(code) {
            let savedData = this.lpContent.find(lp => { return lp.language == this.previewLanguage });

            if(savedData.body != code) {
                if (this.saveTimeout) {
                    clearTimeout(this.saveTimeout);
                }
                this.saveTimeout = setTimeout(this.updateLandingPage, 1000, code);
            }
        },
        async updateLandingPage(code) {
            this.lpContent.forEach(lp => { if(lp.language == this.previewLanguage) { lp.body = code; } });

            this.previewLg = await this.compilePreview(this.lpContent, this.previewLanguage, true);
            this.codePreview = await this.compilePreview(this.lpContent, this.previewLanguage, false);
		},
		saveLPBody() {
            let savedData = this.lpContent.find(lp => { return lp.language == this.previewLanguage });
            let previousData = this.currentLPContent.find(lp => { return lp.language == this.previewLanguage });
	
			if(JSON.stringify(previousData.body) === JSON.stringify(savedData.body)) {
				return;
			}

			landingpageService.updateLandingPage(this.landingPageId, this.previewLanguage, { body: savedData.body }).then(async () => {
                console.debug("LandingPage - updateLandingPage success", this.landingPageId);
                this.alertVariant = 'success';
                this.alertMessage = 'LandingPage.UpdateLandingPageSuccess';
                
				// Update saved Landing Page content
                this.currentLPContent = JSON.parse(JSON.stringify(this.lpContent));

                // Reload Card preview, now that the body has changed
                this.previewSm = await this.compilePreview(this.currentLPContent, this.previewLanguage, true);
                this.$emit('landing_page_reload', this.landingPageId);
            }).catch((error) => {
                console.error("LandingPage - updateLandingPage error", this.landingPageId, error);
                this.alertVariant = 'danger';
                this.alertMessage = 'LandingPage.UpdateLandingPageError';
            }).finally(() => {
                this.loading = false;
                this.$refs.cgLocModalAlert.showAlert();
            });
		},
        undoUnsavedChanges() {
            // Restore original template data to trigger computed variables reloading
            this.currentLP = Object.assign({}, this.landingPage);
            this.currentLPContent = JSON.parse(JSON.stringify(this.lpContent));
            this.companyToSave = [];

            this.$refs.lpModal.hide();
		},
		manageUnsavedInfo(bvModalEvt) {
            let modified = !(this.disableSaveNameButton && this.disableSaveSettingsButton && this.disableSaveBodyButton && this.disableAssignToCompanyButton);
			if(modified) {
				// Prevent modal from closing
				bvModalEvt.preventDefault();
				// Trigger submit handler
				this.$bvModal.show('unsavedLPInfo-'+this.landingPageId);
			} else {
				this.$refs.lpModal.hide();
			}
		},
        async copyLandingPage() {
            let alertVariant = '';
			let alertMessage = '';
            try {
                await landingpageService.copyLandingPage(this.landingPageId, { name: this.landingPage.name, companyId: this.landingPageCopyTargetCompany } );
                alertVariant = 'success';
				alertMessage = 'LandingPage.CopySuccess';
			} catch (error) {
				alertVariant = 'danger';
				alertMessage = 'LandingPage.CopyError';
			} finally {
                this.$emit('landing_page_copied', alertMessage, alertVariant);
                this.$refs.lpModal.hide();
			}
        }
    },
    async mounted() {
        this.getLandingPageContent();

        this.landingPageName = this.landingPage.name;
        
        // Save the Landing Page data in order to detach widget data model from saved data
        this.currentLP = Object.assign({}, this.landingPage);

        this.supportMessage = this.companyData.contact? this.$t('LandingPage.ContactUsLandingPage', { param: this.companyData.contact }) :
            this.$t('LandingPage.ContactSupportLandingPage');
    }
};
</script>

<style lang="less">
.modal-body-full-width {
    padding: 0;
    min-height: 600px;
    iframe {
        min-height: 500px;
    }
    .preview-lp-lg {
        height: 500px;
        // overflow: auto;
        border: @cg-border-light;
    }
    .separator {
        border-bottom: @cg-border-light;
        &.company:last-of-type {
            border-bottom: none;
        }
    }
    .line-height-30 {
        line-height: 30px;
    }
    .tab-content>.tab-pane {
        height: 640px;
        overflow-y: auto;
    }
}
pre[class*="language-"] {
    overflow: initial;
}
.prism-editor__line-numbers {
    position: sticky;
    left: 0px;
}
.ellipsable-text {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.landing-page-modal-footer {
    width: 100%;
    margin: 0;
    button:first-of-type {
        margin: 0 20px;
    }
}
.company-container {
    max-height: 464px;
    overflow-y: scroll;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: @cg-secondary-color-hover;
    border-color: @cg-secondary-color-hover;
    color: @cg-primary-color;
}
.iframe-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.preview-sm {
    height: 300px;
    overflow: hidden;
}
.preview-sm iframe {
    height: 800px;
    margin-left: -50%;
    margin-top: -200px;
    width: 200%;
    -webkit-transform: scale(0.5);
    -moz-transform:scale(0.5);
    -o-transform:scale(0.5);
    -ms-transform:scale(0.5);
}
// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) { 
    .modal-body-full-width {
        iframe {
            min-height: 480px;
        }    
        .preview-lp-lg {
            height: 480px;
        }
    }
}
</style>