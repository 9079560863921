import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import i18n from './plugins/i18n'
import axios from './plugins/axios'
import chartDataLabels from 'chartjs-plugin-datalabels'
import stacked100 from 'chartjs-plugin-stacked100'
import moment from 'moment-timezone'
import isoCountriesLanguages from 'iso-countries-languages_local'
import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'
import Vuex from 'vuex'
import 'prismjs'
import 'prismjs/themes/prism.css'
import VuePrismEditor from 'vue-prism-editor'
import 'vue-prism-editor/dist/VuePrismEditor.css'
import '@voerro/vue-tagsinput/dist/style.css'

import './plugins/filters'

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$isoCountriesLanguages = isoCountriesLanguages;

// Import components
import Modal from './components/Modal.vue'
import AnalyticsTable from './components/tables/AnalyticsTable.vue'
import Notification from './components/dashboard/cards/Notification.vue'
import LocalizedAlert from './components/LocalizedAlert.vue'
import Loader from './components/Loader.vue'
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'
import Select from './components/Select.vue'
import TemplateCard from './components/TemplateCard.vue'
import TemplateModal from './components/TemplateModal.vue'
import LandingPageCard from './components/LandingPageCard.vue'
import VoerroTagsInput from '@voerro/vue-tagsinput'

library.add(fab)
library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('cg-analytics-table', AnalyticsTable)
Vue.component('cg-localized-alert', LocalizedAlert)
Vue.component('cg-modal', Modal)
Vue.component('cg-loader', Loader)
Vue.component('cg-footer', Footer)
Vue.component('cg-notification', Notification)
Vue.component('cg-navbar', Navbar)
Vue.component('cg-select', Select)
Vue.component('cg-template-card', TemplateCard)
Vue.component('cg-template-modal', TemplateModal)
Vue.component('cg-lp-card', LandingPageCard)
Vue.component('prism-editor', VuePrismEditor)
Vue.component('tags-input', VoerroTagsInput)

Vue.use(Vuex);
Vue.use(Vueditor, {});

Vue.config.productionTip = false;

new Vue({
	router,
	i18n,
	chartDataLabels,
	stacked100,
	render: function (h) { return h(App) }
}).$mount('#app');
