import axios from '../plugins/axios.js'

class CampaignService {

	changeCampaignStatus(companyId, campaignId, status) {
		return axios.put(`/campaign/${campaignId}/status`, { companyId: companyId, status: status });
	}

	changeCampaignSuspendStatus(campaignId, status) {
		return axios.put(`/campaign/${campaignId}/suspend`, { status: status });
	}

	getAllCampaign() {
		return axios.get("/campaigN-All");
	}

	getCampaignTemplates(campaignId, isIncluded) {
		return axios.get(`/campaign/${campaignId}/template`, { params: { status: isIncluded } });
	}

	searchTemplates( campaignId, searchText, searchStatus) {
		return axios.get(`/campaign/${campaignId}/template/search`, {
			params: { searchText: searchText, searchStatus: searchStatus }
		});
	}

	changeTemplateStatus(campaignId, templateId, statusValue) {
		return axios.put(`/campaign/${campaignId}/template/${templateId}/status`, { status: statusValue });
	}

	getAllCampaigns() {
		return axios.get("/campaigN-All");
	}

	updateCampaignDates(campaignId, dates) {
		return axios.put(`/campaign/${campaignId}/dates`, dates);
	}

	updateRemediationTemplates(campaignId, templates) {
		return axios.post(`/campaign/${campaignId}/remediation/templates`, { templates: templates });
	}

	initializeRemediationCampaign(campaignId, companyId, campaignDuration, targets, type) {
		return axios.post(`/campaign/${campaignId}/remediate`, { companyId: companyId, duration: campaignDuration, targets: targets, type: type });
	}

	getCompletedRemediationTargets(campaignId) {
		return axios.get(`/campaign/${campaignId}/remediations/targets`);
	}

	getCompletedRemediationClicks(campaignId) {
		return axios.get(`/campaign/${campaignId}/remediations/clicks`);
	}

	getCompletedRemediationTemplates(campaignId) {
		return axios.get(`/campaign/${campaignId}/remediations/templates`);
	}

	readyToApprove(companyId) {
		return axios.get(`/campaign/ready`, {params: { companyId: companyId }});
	}

	deleteCampaign(campaignId) {
		return axios.delete(`/campaign/${campaignId}`);
	}

	remakeCampaign(campaignId, companyId) {
		return axios.post(`/campaign/${campaignId}/remake`, { companyId: companyId });
	}

}

export default new CampaignService();