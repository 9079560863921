<template>
	<b-alert id="notification" :show="hasPendingAction" variant="warning" dismissible>
		<b-row>
			<b-col cols="auto">
				<span class="mb-0">{{ $t('Dashboard.SuggestedCampaignMessage') }}</span>
			</b-col>
			<b-col>
				<router-link to="/campaigns">
					<b-button variant="primary" size="sm" class="btn-action">{{ $t("Dashboard.GoToCampaign") }}</b-button>
				</router-link>
			</b-col>
		</b-row>
	</b-alert>
</template>
<script>
import CompanyService from '../../../services/company.service';
import { Campaign } from '../../../../../server/common/constants';

export default {
	name: "cg-notification",
	data() {
		return {
			campaignEnd: null,
			companyId: localStorage.getItem("cg-company")
		}
	},
	computed: {
		hasPendingAction() {
			return !!this.campaignEnd;
		}
	},
	methods: {
		checkPendingApprovalCampaign() {
			var option = { status: Campaign.Status.PendingApproval };
			if(this.companyId) {
				CompanyService.getCampaignList(this.companyId, option).then((result) => {
					if (result.data && result.data.length > 0) {
						result.data.forEach((campaign) => {
							if(campaign.status == 1) {
								this.campaignEnd = result.data[0].planned_start;
							}
						});
					}
				}).catch((err) => {
					console.error("Notifications - checkPendingApprovalCampaign: ERROR", err);
				});
			}
		}
	},
	mounted() {
		this.checkPendingApprovalCampaign();
	}
};
</script>