import axios from 'axios'
import router from '../router'

var axiosInstance = axios.create({
    baseURL: '/api',
    headers: {
        "Content-type": "application/json"
    }
});

axiosInstance.interceptors.response.use(
    function (response) {
        return Promise.resolve(response);
    }, 
    function (error) {
        if (error.response.status === 401) {
            router.push("/login");
        }
        return Promise.reject(error);
    });

export default axiosInstance;