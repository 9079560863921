import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import loginService from '../services/login.service';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'index',
		beforeEnter: (to, from, next) => {
			next('/dashboard')
		}
	}, {
		path: '/login',
		name: 'login',
		component: Login
	}, {
		path: '/dashboard',
		name: 'dashboard',
		component: function () {
			return import('../views/Dashboard.vue')
		}
	}, {
		path: '/analytics',
		name: 'analytics',
		component: function () {
			return import('../views/Analytics.vue')
		}
	}, {
		path: '/campaigns',
		name: 'campaigns',
		component: function () {
			return import('../views/Campaigns.vue')
		}
	},  {
		path: '/remediation',
		name: 'remediation',
		component: function () {
			return import('../views/Remediation.vue')
		}
	}, { 
		path: '/administration',
		component: function () {
			return import('../views/Administration.vue')
		},
		children: [
			{
				path: 'account',
				component: function () {
					return import('../views/Account.vue')
				}
			},
			{
				path: 'roles',
				component: function () {
					return import('../views/Roles.vue')
				}
			},
			{
				path: 'help',
				component: function () {
					return import('../views/Help.vue')
				}
			},
			{
				path: 'panel',
				component: function () {
					return import('../views/Panel.vue')
				}
			}, 
			{
				path: 'company',
				component: function () {
					return import('../views/Company.vue')
				}
			}, 
			{
				path: 'template',
				component: function () {
					return import('../views/Template.vue')
				}
			},
			{
				path: 'landingpage',
				component: function () {
					return import('../views/LandingPage.vue')
				}
			},
			{
				path: 'targets',
				component: function () {
					return import('../views/Targets.vue')
				}
			}
		]
	}, {
		path: '/profile',
		name: 'profile',
		component: function () {
			return import('../views/Profile.vue')
		}
	}, {
		path: '/company',
		name: 'company',
		component: function () {
			return import('../views/Company.vue')
		}
	}, { path: '*', redirect: '/' }
]

const router = new VueRouter({
	routes
});

router.beforeResolve(async (to, from, next) => {
	var session = await loginService.validateSession();
	if (to.path !== '/login' && !session.data.account) {
		next('/login');
	} else {
		if (session && session.data.account) {
			// if session exists and is valid set default header for csurf validation
			let accountData = session.data.account;
			router.app.$http.defaults.headers.common['X-CSRF-TOKEN'] = session.data.csrfToken;
			router.app.$i18n.locale = accountData.language || 'en';
			if(router.app.$langOptions) {
                router.app.$langOptions.forEach(opt => {
                    opt.text = router.app.$t(`Languages.${opt.value}`) 
                })
            }
			if (!router.app.$account) {
				Vue.prototype.$account = accountData;
				
			} else {
				router.app.$account = accountData;
			}
			if(!accountData.timezone) {
				router.app.$account.timezone = router.app.$moment.tz.guess();
			}
		}
		if (to.path == '/login' && session && session.data.account) {
			next('/');
		} else {
			next();
		}
	}
})

export default router;