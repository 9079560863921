<template>
  <b-list-group horizontal class="align-items-center">
    <b-list-group-item><b>{{ label }}</b></b-list-group-item>
    <b-list-group-item class="ml-2">
      <b-form-select
        size="sm"
        v-model="model"
        :options="options"
      ></b-form-select>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: "cg-select",
  data: function() {
    return {};
  },
  props: ["model", "options", "label"]
};
</script>

<style lang="less">

// Small devices (landscape phones, 576px and up)
@media only screen and (max-width: 575px) {
}

// Medium devices (tablets, 768px and up)
@media only screen and (max-width: 767px) {
}

// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) {
}

// Extra large devices (large desktops, 1200px and up)
@media only screen and (max-width: 1199px) {
}
</style>