<template>
	<div :id="data.ref_id+'_wrapper'">
		<b-table
			:id="data.ref_id"
			:primary-key="data.primary_key"
			:ref="data.ref_id"
			:fields="data.fields"
			:items="data.items"
			:sort-by.sync="data.sortBy"
			:sort-desc.sync="data.sortDesc"
			:tbody-tr-class="variant"
			:per-page="data.perPage"
			:current-page="data.currentPage"
			selectable
			select-mode="single"
			sticky-header
			no-border-collapse
			responsive
			hover
			striped
			show-empty
			@row-clicked="handleClick"
			@sort-changed="handleSortChange"
			:emptyFilteredText="$t('Analytics.Table.EmptyFilter')"
			:empty-text="$t('Analytics.Table.Empty')"
		>
			<template v-slot:cell(preview)="data">
				<div class="text-center" @click.stop>
					<font-awesome-icon :icon="['fas', 'eye']" @click="previewClick(data)"/>
				</div>
			</template>
		</b-table>

		<b-row v-if="data && data.paginated" align-h="end">
			<b-col class="mb-2 d-block d-sm-block d-md-none">
				<label for="itemsPerPageSmall" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
				<b-form-select id="itemsPerPageSmall" class="float-right" v-model="data.perPage" :options="pageSizeOptions"></b-form-select>
			</b-col>
			<b-col class="text-right pt-1 pr-0 d-none d-sm-none d-md-block">
				<label for="itemsPerPage" class="text-right">{{ $t('Analytics.Table.ItemsPerPage') }}</label>
			</b-col>
			<b-col cols="12" md="auto" class="mb-2 pr-0 d-none d-sm-none d-md-block">
				<!-- Items per Page widget -->
				<b-form-select id="itemsPerPage" class="float-right" v-model="data.perPage" :options="pageSizeOptions"></b-form-select>
			</b-col>

			<b-col cols="12" md="auto">
				<!-- Pagination widget -->
				<b-pagination v-model="data.currentPage" :total-rows="data.totalRows" :per-page="data.perPage" align="right" :class="variant"
					:aria-controls="data.ref_id" :disabled="data.perPage<1"></b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { TablePaginationSize } from "../../../../server/common/constants";

export default {
	name: "cg-analytics-table",
	computed: {
		pageSizeOptions() {
			return TablePaginationSize || [];
		}
	},
	props: {
		data: Object,
		variant: String
	},
	methods: {
		handleClick(item) {
			this.$emit("rowClicked", item);
		},
		handleSortChange(context) {
			this.$emit("sortChanged", context);
		},
		selectRow(ref, index) {
			this.$refs[ref].selectRow(index);
		},
		clearSelected(ref) {
			this.$refs[ref].clearSelected();
		},
		previewClick(data) {
			this.$emit("previewClicked", data.item);
		}
	}
};
</script>

<style lang="less">
.table {
	td {
		padding: 0.4rem;
	}
	thead th {
		border-bottom: @cg-border-light;
	}
	th {
		border-top: none;
		padding: 0.6rem;
	}
	tr:first-of-type {
		td {
			border-top: none;
		}
	}
	tr:last-of-type {
		td {
			border-bottom: none;
		}
	}
}
.b-table-sticky-header {
	border: @cg-border-light;
}
tr.b-table-row-selected.table-active {
	border: none;
	> td {
		background-color: transparent !important;
	}
	&.cerulean {
		background-color: #b8d6ff !important;
	}
	&.forest {
		background-color: #b5e5da !important;
	}
	&.dawn {
		background-color: #ffd8b0 !important;
	}
}
.table-striped tbody tr:nth-of-type(odd) {
	&.cerulean {
		background-color: rgba(0, 123, 255, 0.08);
	}
	&.forest {
		background-color: rgba(26, 188, 156, 0.08);
	}
	&.dawn {
		background-color: rgba(255, 151, 18, 0.08);
	}
}
.table.unstriped tbody tr {
	&.cerulean {
		background-color: rgba(0, 123, 255, 0.08);
	}
	&.forest {
		background-color: rgba(26, 188, 156, 0.08);
	}
	&.dawn {
		background-color: rgba(255, 151, 18, 0.08);
	}
	&.dusk {
		background-color: #ececec;
		color: #62686f;
	}
}
.table-hover tbody tr {
	&.cerulean:hover {
		background-color: rgba(0, 123, 255, 0.5) !important;
		td {
			background-image: none !important;
			background-color: transparent;
		}
	}
	&.forest:hover {
		background-color: rgba(26, 188, 156, 0.5) !important;
		td {
			background-image: none !important;
			background-color: transparent;
		}
	}
	&.dawn:hover {
		background-color: rgba(255, 151, 18, 0.5) !important;
		td {
			background-image: none !important;
			background-color: transparent;
		}
	}
}
.dawn {
	.page-item.active .page-link {
		background-color: rgba(255, 151, 18, 0.5) !important;
		border-color: rgba(255, 151, 18, 0.5) !important;
		color: @cg-primary-color;
	}
	.page-item.disabled.active .page-link {
		background-color: #d2b692 !important;
	}
	.page-link {
		padding: 6px 12px;
		color: @cg-primary-color;
	}
}
.cerulean {
	.page-item.active .page-link {
		background-color: rgba(0, 123, 255, 0.5) !important;
		border-color: rgba(0, 123, 255, 0.5) !important;
		color: @cg-primary-color;
	}
	.page-item.disabled.active .page-link {
		background-color: rgba(88, 126, 167, 0.5) !important;
	}
	.page-link {
		padding: 6px 12px;
		color: @cg-primary-color;
	}
}
.forest {
	.page-item.active .page-link {
		background-color: rgba(26, 188, 156, 0.5) !important;
		border-color: rgba(26, 188, 156, 0.5) !important;
		color: @cg-primary-color;
	}
	.page-item.disabled.active .page-link {
		background-color: rgba(98, 150, 139, 0.5) !important;
	}
	.page-link {
		padding: 6px 12px;
		color: @cg-primary-color;
	}
}
.form-row.form-group {
	.col-form-label {
		padding-bottom: 0px;
	}
}
</style>