<template>
	<div id="app">
		<cg-navbar ref="navbar" @removeSession="removeSession"></cg-navbar>
		<div id="page">
			<router-view 
				@loginSuccess="createSession" 
				@companyUpdated="updateCompanyInfo($event)" 
				@companyToggleArchive="toggleCompanyArchive($event)" 
				@companyDeleted="deleteCompanyInfo($event)" 
				@sessionExpired="hideNavbar"
				@languageChanged="updateLocale($event)"
			></router-view>
		</div>
		<cg-footer/>
	</div>
</template>

<script>
import Vue from 'vue';

export default {
	name: "app",
	data: function () {
		return {
			username: "",
			permission: []
		};
	},
	computed: {},
	methods: {
		createSession: function (payload) {
			this.username = payload.displayname;
			Vue.prototype.$account = payload;
			this.$refs.navbar.setCompany(payload.current_company);
			this.$refs.navbar.displayNavbar(payload);
		},
		removeSession: function () {
			console.log('remove session');
			this.hideNavbar();
		},
		hideNavbar() {
			this.$refs.navbar.hideNavbar();
		},
		updateCompanyInfo(event) {
			this.$refs.navbar.updateCompanyInfo(event);
		},
		deleteCompanyInfo(event) {
			this.$refs.navbar.deleteCompanyInfo(event);
		},
		toggleCompanyArchive(event) {
			this.$refs.navbar.toggleCompanyArchive(event);
		},
		updateLocale(event) {
			this.$refs.navbar.updateLocale(event);
		}
	},
	beforeUpdate() {
		if(this.$account) {
			Vue.prototype.$checkPermission = function(permissions) {
				if(!permissions || !this.$account) {
					return false;
				}
				if(typeof permissions == 'string') {
					permissions = permissions.split(',');
				}

				const account_perms = this.$account.permissions.filter((item) => item.company_id == this.$account.current_company).map((item) => item.permissions)[0];

				return permissions.every((perm) => { return account_perms.includes(perm.trim()) });
			}

			this.$refs.navbar.displayNavbar(this.$account);
		}	
	}
};
</script>

<style lang="less">
@import "./stylesheets/base.less";
#page {
	padding-top: @cg-root-container-margin-top !important;
	.spinner-border {
		color: @cg-primary-color !important;
	}
}
</style>
