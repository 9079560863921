<template>
	<transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
		<div v-if="display" class="white-overlay">
			<div class="loader-wrapper">
				<b-spinner variant="primary" class="spinner-large"></b-spinner>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	name: 'cg-loader',
	props: {
		display: {
			default: false
		}
	}
}
</script>
<style scoped lang="less">
.spinner-large {
	width: 4rem;
	height: 4rem;
}
.spinner-border {
	border: 0.2rem solid rgba(0, 0, 0, 0.15);
	border-right-color: @cg-primary-color;
}
.white-overlay {
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 9999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	margin: 0;
}
.loader-wrapper {
	position: relative;
	top: 45%;
	width: 65px;
	margin: 0 auto;
}
</style>