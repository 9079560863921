import axios from '../plugins/axios.js'

class SysPropService {

	getAllRoles() {
		return axios.get("/sysprop/roles");
	}

	getAllPermissions() {
		return axios.get("/sysprop/permissions");
	}

	getLanguages() {
		return axios.get("/sysprop/languages");
	}

	getContact() {
		return axios.get("/sysprop/contact");
	}

	getPasswordSettings() {
		return axios.get("/sysprop/password_settings");
	}
	
	getConfig() {
		return axios.get("/sysprop/config", { params: { seed: Math.random().toString(36).substring(2, 15) } });
	}

}

export default new SysPropService();