<template>
	<transition name="fade">
		<div class="white-overlay" v-if="dismissCountDown">
			<div class="alert-wrapper">
				<b-alert :show="dismissCountDown" :variant="alertVariant" dismissible @dismiss-count-down="countDownChanged">
					<span v-html="$tc(alertMessage, alertCount, alertParams)"></span>
				</b-alert>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "cg-localized-alert",
	data: function () {
		return {
			dismissCountDown: this.dismissSecs == true? true : 0
		}
	},
	props: {
		alertVariant: {
			type: String,
			default: 'success'
		}, 
		alertMessage: {
			type: String,
			required: true
		},
		alertParams: {
			type: Object,
			required: false
		},
		alertCount: {
			type: Number,
			required: false,
			validator: propValue => {
				return propValue > -1;
			}
		},
		dismissSecs: {
			type: [Number, Boolean],
			default: 5
		}
	},
	methods: {
		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},
		showAlert() {
			this.dismissCountDown = this.dismissSecs;
		},
		hideAlert() {
			this.dismissCountDown = 0;
		}
	}
};
</script>

<style scoped lang="less">
.white-overlay {
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
}
.alert-wrapper {
	position: relative;
	box-shadow: @cg-box-shadow;
	top: 62px;
	max-width: 80%;
	margin: 0 auto;
	.alert {
		margin-bottom: 0;
	}
}
.fade-enter-active, .fade-leave-active {
	transition: all 1s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
	transform: translateY(-62px);
}

// Medium devices (tablets, 768px and up)
@media only screen and (max-width: 767px) {
	.alert-wrapper {
		top: 55px;
	}
	.fade-enter, .fade-leave-to {
		transform: translateY(-55px);
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.alert-wrapper {
		top: 62px;
	}
	.fade-enter, .fade-leave-to {
		transform: translateY(-62px);
	}
}

// Extra large devices (large desktops, 992px to 1200px)
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.alert-wrapper {
		top: 73px;
	}
	.fade-enter, .fade-leave-to {
		transform: translateY(-73px);
	}
}
</style>