<template>
    <div class="dashboard-col footer-wrapper">
        <span v-if="$te('General.FooterMessage')">
            {{ $t('General.FooterMessage') }}
        </span>
	</div>
</template>
<script>
export default {
	name: 'cg-footer',
}
</script>
<style scoped lang="less">
.footer-wrapper {
	padding: 0 15px 0.5rem;
	display: flex;
	justify-content: flex-end;
}
</style>