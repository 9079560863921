import axios from '../plugins/axios.js'

class CompanyService {

	getCompany(companyId) {
		return axios.get(`/company/${companyId}`);
	}

	createCompany(companyObj) {
		return axios.post("/company", companyObj);
	}

	updateCompany(companyId, body) {
		return axios.put(`/company/${companyId}`, body);
	}

	changeCompanyArchivedStatus(companyId, archived) {
		return axios.put(`/company/${companyId}/archive`, { archived: archived });
	}

	deleteCompany(companyId) {
		return axios.delete(`/company/${companyId}`);
	}

	softDeleteCompany(companyId) {
		return axios.put(`/company/${companyId}/hiatus`);
	}	

	getCompanyTestTargets(companyId, params) {
		return axios.get(`/company/${companyId}/test`, { params: params });
	}

	getTargetCountries(companyId) {
		return axios.get(`/company/${companyId}/country`);
	}

	updateCompanyTestTargets(companyId, targets, campaignId, campaignType, saveOnly) {
		return axios.put(`/company/${companyId}/test`, { targets: targets, campaignId: campaignId, campaignType: campaignType, saveOnly: saveOnly });
	}

	getCampaignStats(companyId, params) {
		return axios.get(`/company/${companyId}/stats`, { params: params });
	}

	getActiveTargets(companyId, params) {
		return axios.get(`/campaign/${companyId}/active`, { params: params });
	}

	getOrgLevelRisk(companyId, code, queryParams) {
		return axios.get(`/company/${companyId}/orgLevelRisk`, { params: Object.assign({ code }, queryParams) });
	}

	getReliabilityScore(companyId, queryParams) {
		return axios.get(`/company/${companyId}/reliability`, { params: queryParams });
	}
	
	getRiskByLocation(companyId, queryParams) {
		return axios.get(`/company/${companyId}/riskByLocation`, { params: queryParams });
	}

	getAnalyticsSummary(companyId, code, queryParams, context) {
		return axios.get(`/company/${companyId}/summary`, { params: Object.assign({ code, context }, queryParams) });
	}

	getOrgSummary(companyId, queryParams) {
		return axios.get(`/company/${companyId}/orgSummary`, { params: queryParams });
	}
	
	getNumAttackedTargets(companyId, queryParams) {
		return axios.get(`/company/${companyId}/numAttackedTargets`, { params: queryParams });
	}

	getGlobalStats(companyId, queryParams) {
		return axios.get(`/company/${companyId}/globalStats`, { params: queryParams });
	}

	getCompanyList() {
		return axios.get('/company');
	}

	assignCurrentCompany(companyId) {
		return axios.put(`/company/${companyId}/current`);
	}

	hasRemediationTab(companyId) {
		return axios.get(`/company/${companyId}/remediations/tab`);
	}

	cleanUpPendingRemediations(companyId) {
		return axios.get(`/company/${companyId}/remediations/cleanup`);
	}

	getCurrentRemediationCampaigns(companyId, details) {
		return axios.get(`/company/${companyId}/remediations/${details}`);
	}

	createRemediationCampaign(companyId, type) {
		return axios.put(`/company/${companyId}/remediation/${type}`);
	}

	getRemediationLightTargets(companyId, queryParams) {
		return axios.get(`/company/${companyId}/remediations/filters`, { params: queryParams });
	}

	getCompletedFinalRemediationCampaigns(companyId, type) {
		return axios.get(`/company/${companyId}/remediation/${type}`);
	}

	getCompanyOrgLabels(companyId) {
		return axios.get(`/company/${companyId}/orgLabels`);
	}

	getCompanyOrgValues(companyId) {
		return axios.get(`/company/${companyId}/orgValues`);
	}

	getRolesString(companyId) {
		return axios.get(`/company/${companyId}/account`);
	}

	assignLandingPage(companyId, landingPageId, assignAction) {
		return axios.put(`/company/${companyId}/landingpage`, { landingPageId: landingPageId, assignAction: assignAction });
	}

	getCampaignList(companyId, queryParams) {
		var opt = { params: {} }
		for(var key in queryParams) {
			opt.params[key] = queryParams[key];
		}
		return axios.get(`/company/${companyId}/campaign`, opt);
	}

	getTargets(companyId, searchText, page, numRes, sortBy, sortDesc, suspended) {
		let opt = { params: { searchText: searchText, page: page, numRes: numRes, sortBy: sortBy, sortDesc: sortDesc, suspended: suspended } };
		return axios.get(`/company/${companyId}/target`, opt);
	}

	getTargetCount(companyId) {
		return axios.get(`/company/${companyId}/targetCount`, {});
	}

	createTarget(companyId, data) {
		return axios.post(`/company/${companyId}/target`, data);
	}

	getTemplateLanguages(companyId) {
		// RFI: provide a mean to assign some templates to a specific Company only
		return axios.get(`/company/${companyId}/template_languages`);
	}

	getLandingPageLanguages(companyId) {
		return axios.get(`/company/${companyId}/landing_page_languages`);
	}

	getLandingPage(companyId, templateId, language) {
		return axios.get(`/company/${companyId}/landing_page/${templateId}/lang/${language}`);
	}

	importTargets(companyId, data) {
		return axios.post(`/company/${companyId}/target/bulk`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	uploadLogo(companyId, data) {
		return axios.post(`/company/${companyId}/logo`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	createReport(companyId, queryParams) {
		return axios.get(`/company/${companyId}/report`, { params: queryParams });
	}
}

export default new CompanyService();