<template>
	<b-navbar toggleable="lg" type="dark" variant="ligth" fixed="top">
		<router-link to="/dashboard">
			<b-navbar-brand>
				<b-img fluid center src="../assets/images/cg_logo_white.svg" 
					class="brand-logo d-inline-block align-top" :alt="$t('General.CyberGuruLogo')"></b-img>
			</b-navbar-brand>
		</router-link>

		<b-navbar-toggle target="nav-collapse">
			<font-awesome-icon :icon="['fas', 'bars']" />
		</b-navbar-toggle>

		<b-collapse id="nav-collapse" is-nav>
			<!-- Left aligned nav items -->
			<b-navbar-nav v-if="logged">
				<b-nav-item v-if="$checkPermission('view-campaign-summary')">
					<router-link to="/dashboard">
						<font-awesome-icon :icon="['fas', 'columns']" class="mr-1"/>
						<span>{{ $t("Navbar.Dashboard") }}</span>
					</router-link>
				</b-nav-item>
				<b-nav-item v-if="$checkPermission('view-campaign-summary')">
					<router-link to="/analytics">
						<font-awesome-icon :icon="['fas', 'chart-pie']" class="mr-1"/>
						<span>{{ $t("Navbar.Analytics") }}</span>
					</router-link>
				</b-nav-item>
				<b-nav-item v-if="$checkPermission('view-campaign,view-template')">
					<router-link to="/campaigns">
						<font-awesome-icon :icon="['fas', 'tasks']" class="mr-1"/>
						<span>{{ $t("Navbar.Campaigns") }}</span>
					</router-link>
				</b-nav-item>
				<div v-if="$checkPermission('view-remediation')">
					<b-nav-item v-if="hasRemediationTab">
						<router-link to="/remediation">
							<font-awesome-icon :icon="['fas', 'bezier-curve']" class="mr-1"/>
							<span>{{ $t("Navbar.Remediation") }}</span>
						</router-link>
					</b-nav-item>
				</div>
			</b-navbar-nav>

			<!-- Right aligned nav items -->
			<b-navbar-nav class="nav-rigth-menu ml-auto">
				<b-nav-item-dropdown right v-if="logged && filteredCompanies.length > 1">
					<template v-slot:button-content>
						<img class="mr-2" :src="companyLogo" :alt="$t('General.CompanyLogo')" height="24">
						<p>{{ companyName }}</p>
					</template>
					<b-dropdown-item
						v-for="(company, index) in filteredCompanies"
						v-bind:key="index"
						href="#"
						@click="changeCompany(company.company_id)"
					>{{ company.name }}</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-text v-if="logged && filteredCompanies.length == 1">
					<img class="mr-2" :src="companyLogo" :alt="$t('General.CompanyLogo')" height="24">
					<p>{{ companyName }}</p>
				</b-nav-text>
				
				<div class="vertical-separator mx-2" v-if="logged && helpLink">|</div>

				<b-nav-text v-if="logged && helpLink">
					<a :href="helpLink" target="_blank" class="nav-link-help">
						<font-awesome-icon :icon="['fas', 'question-circle']" class="mr-1"/>
						<span>{{ $t("Navbar.Help") }}</span>
					</a>
				</b-nav-text>
				
				<div class="vertical-separator mx-2" v-if="logged">|</div>

				<b-nav-item-dropdown right v-if="logged">
					<template v-slot:button-content>
						<font-awesome-icon :icon="['fas', 'user']" class="mr-1"/>
						<span>{{ username }}</span>
					</template>
					<b-dropdown-item>
						<router-link to="/profile">
							<font-awesome-icon :icon="['fas', 'id-card']" class="mr-1"/>
							<span>{{ $t("Navbar.Profile") }}</span>
						</router-link>
					</b-dropdown-item>
					<b-dropdown-item v-if="canAccessAdministration">
						<router-link to="/administration/panel">
							<font-awesome-icon :icon="['fas', 'user-cog']" class="mr-1"/>
							<span>{{ $t("Navbar.Administration") }}</span>
						</router-link>
					</b-dropdown-item>
					<b-dropdown-item v-if="$checkPermission('list-company') && !canAccessAdministration">
						<router-link to="/company">
							<font-awesome-icon :icon="['fas', 'building']" class="mr-1"/>
							<span>{{ $t("Navbar.Company") }}</span>
						</router-link>
					</b-dropdown-item>
					<b-dropdown-item @click="logout">
						<font-awesome-icon :icon="['fas', 'sign-out-alt']" class="mr-1"/>
						<span>{{ $t("Navbar.LogOut") }}</span>
					</b-dropdown-item>
				</b-nav-item-dropdown>

				<!-- User sees login page -->
				<b-nav-item-dropdown right v-if="!logged">
					<template v-slot:button-content>
						<font-awesome-icon :icon="['fas', 'globe']" class="mr-1"/>
						<span>{{ $i18n.locale | uppercase }}</span>
					</template>
					<b-dropdown-item
						href="#"
						v-for="(lang, index) in langOptions"
						v-bind:key="index"
						@click="$i18n.locale = lang.value"
					>{{ lang.value | uppercase }}</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>

<script>
import loginService from '../services/login.service';
import syspropService from '../services/sysprop.service';
import companyService from '../services/company.service';
import Vue from 'vue';

export default {
	name: "cg-navbar",
	data: function () {
		return {
			logged: false,
			companyId: localStorage.getItem("cg-company"),
			companyName: "",
			companyLogo: "",
			username: "",
			hasRemediationTab: false,
			helpLink: null,
			companies: [],
			permissions: [],
			hasSuggestedToApprove: false,
			langOptions: [],
			canAccessAdministration: false,
			showAdministrationPermission: [
				'list-account',
				'view-company',
				'list-template',
				'view-target',
				'list-role',
				'view-landing-page'
			]
		};
	},
	computed: {
		filteredCompanies() {
			return this.companies.filter((c) => !c.archived);
		}
	},
	methods: {
		async logout() {
			try {
				await loginService.logout();
				localStorage.removeItem('cg-company');
				this.$emit("removeSession");
				this.$router.push("/login");
			} catch (error) {
				console.log(error);
			}
		},
		async changeCompany(company) {
			this.changeCurrentCompany(company);
			await companyService.assignCurrentCompany(company);

			// In order to correctly display all the pages, trigger App reload
			if (this.$route.path !== "/dashboard") {
				this.$router.replace("/");
			} else {
				location.reload();
			}
		},
		// Show Remediation tab only if company can see at least one remediation
		async showRemediationTab(company) {
			let result = await companyService.hasRemediationTab(company);
			this.hasRemediationTab = result && result.data? result.data.length > 0 : false;
		},
		changeCurrentCompany(company) {
			Vue.prototype.$account.current_company = company;
			this.setCompany(company);
		},
		setCompany(company) {
			if (company) {
				localStorage.setItem("cg-company", company);
			} else {
				localStorage.removeItem("cg-company");
			}
			this.companyLogo = "/api/company/" + company + "/logo";
		},
		async updateLocale(locale) {
			this.isoLanguages = await this.$isoCountriesLanguages.getLanguages(locale);

			let arrayLang = Object.keys(this.isoLanguages).map((i) => { return {
				key: i, value: this.isoLanguages[i]
			}});

			this.isoLanguages = arrayLang.sort((a, b) => { return ('' + a.value).localeCompare('' + b.value); });
			let objectLang = {};
			this.isoLanguages.forEach((i) => objectLang[i.key] = i.value );
			this.isoLanguages = objectLang;
			Vue.prototype.$langs = this.isoLanguages;
		},
		async displayNavbar(payload) {
			this.logged = true;

			// Sort Companies by name
			this.companies = payload.companies.sort((a, b) => { return ('' + a.name).localeCompare(b.name); });
			this.username = payload.displayname;

			if(localStorage.getItem("cg-company")) {
				this.companies.forEach((comp)=> {
					if(comp.company_id == localStorage.getItem("cg-company")) {
						this.companyLogo = "/api/company/" + comp.company_id + "/logo";
						this.companyName = comp.name;
					}
				});
			}

			this.canAccessAdministration = this.showAdministrationPermission.some((permission) => { return this.$checkPermission(permission) });

			if(this.$checkPermission('view-remediation')) {
				await this.showRemediationTab(payload.current_company);
			}

			syspropService.getConfig().then((res) => {
				this.helpLink = res.data.defaults.helpLink || null;
			});
			if(!this.$langs) {				
				this.updateLocale(payload.language);
			}
		},
		hideNavbar() {
			this.logged = false;
		},
		async toggleCompanyArchive(company) {
			this.companies.forEach((comp, i) => { 
				if(comp.company_id == company.company_id) {
					Vue.set(this.companies[i], 'archived', company.archived);
				}
			});

			if(company.company_id == localStorage.getItem('cg-company')) {
				let remainingCompanies = this.companies.filter((c) => !c.archived);
				if(remainingCompanies && remainingCompanies.length > 0) {
					await this.changeCompany(remainingCompanies[0].company_id);
				} else {
					this.logout();
				}
			}
		},
		async updateCompanyInfo(company) {
			if(company.company_id == localStorage.getItem('cg-company')) {
				this.companyLogo = "/api/company/" + company.company_id + "/logo?"+Date.now();
				this.companyName = company.name;
			}			
			this.companies.forEach((comp, i) => { 
				if(comp.company_id == company.company_id) {
					Vue.set(this.companies[i], 'name', company.name);
				}
			});
			
			this.companies = this.companies.sort((a, b) => { return ('' + a.name).localeCompare(b.name); });
		},
		async deleteCompanyInfo(company) {
			await this.changeCompany(company.company_id);
		}
	},
	async mounted() {
		var self = this;
		Vue.prototype.$langOptions = [];
		syspropService.getLanguages().then(function (res) {
			res.data.forEach(function (language) {
				self.$langOptions.push({
					value: language.language_id,
					text: self.$t(`Languages.${language.language_id}`)
				});
			});
			self.langOptions = self.$langOptions;
		}).catch(function (err) {
			console.log(err);
		});
    }
};
</script>

<style lang="less">
.navbar {
	background-color: @cg-primary-color;
	.brand-logo {
		width: 75px;
	}
	.navbar-brand {
		margin-right: 1.5rem;
		vertical-align: middle;
	}
	.navbar-toggler {
		border: none;
		color: @cg-primary-light-text-color;
	}
	a {
		color: @cg-primary-light-text-color;
		&.router-link-exact-active {
			color: @cg-secondary-color;
		}
	}
	.profile {
		width: auto;
		height: 25px;
	}
	.dropdown-menu {
		max-width: 300px;
		max-height: 500px;
		overflow: auto;
	}
	.dropdown-menu-right a {
		color: @cg-primary-color;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		background-color: #ffffff;
		text-decoration: underline;
	}
}
.vertical-separator {
	color: @cg-primary-light-text-color;
	line-height: 40px;
}
.navbar-dark .navbar-nav {
	.nav-link {
		color: @cg-primary-light-text-color;
		text-align: initial;
	}
	.nav-link p, .navbar-text p {
		max-width: 150px !important;
		display: inline-block;
		overflow-x: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: -8px;
	}
	ul > a, ul > a:hover, .show > .nav-link, .nav-link-help, .nav-link-help:hover, .nav-link:focus {
		color: @cg-primary-light-text-color;
	}
	.nav-link:hover {
		color: @cg-primary-light-text-color;
		text-decoration: underline;
	}
	.navbar-text {
		color: white;
		cursor: default;
		padding: .5rem;
	}
}

// Small devices (landscape phones, 576px and up)
@media only screen and (max-width: 575px) { }

// Medium devices (tablets, 768px and up)
@media only screen and (max-width: 767px) {
	.navbar {
		.brand-logo {
			width: 60px;
		}
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) {
	.navbar {
		.dropdown-menu {
			background-color: @cg-primary-color;
			border: none;
			color: @cg-primary-light-text-color;
		}
		.dropdown-menu-right a {
			color: @cg-primary-light-text-color;
		}
		.dropdown-item:focus,
		.dropdown-item:hover {
			background-color: @cg-primary-color;
			text-decoration: underline;
		}
	}
	.vertical-separator {
		display: none;
	}
	.navbar-dark .navbar-nav .navbar-text {
		padding-right: 0;
		padding-left: 0;
	}
	.navbar-collapse {
		margin-top: 15px;
		transition: all 0.2s ease;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media only screen and (max-width: 1199px) {
	
}
</style>