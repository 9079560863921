import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function patchCatalog(target, source) {
    for(let p in target) {
        if(source[p]) {
            if(typeof source[p] == 'object') {
                patchCatalog(target[p],source[p])
            }
            else {
                target[p] = source[p]
            }
        }
    }
}

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /^\.\/[A-Za-z0-9-,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  const locales_custom = require.context('../locales', true, /^\.\/cust_[A-Za-z0-9-,\s]+\.json$/i)
  locales_custom.keys().forEach(key => {
    const matched = key.match(/cust_([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      patchCatalog(messages[locale],locales_custom(key));
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
